<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <!-- <b-form-group
            id="input-group-posyandu"
            label="Posyandu:"
            label-for="input-posyandu"
          >
            <treeselect
              v-model="form.posyandu_id"
              :multiple="false"
              placeholder="Pilih Posyandu"
              :options="posyandu_list"
            />
            <small class="text-danger">{{ error.posyandu_id }}</small>
          </b-form-group> -->

          <b-form-group
            id="input-group-registration-number"
            label="NIK Balita:"
            label-for="input-registration-number"
          >
            <b-form-input
              id="input-registration-number"
              v-model="form.registration_number"
              placeholder="NIK Balita"
            ></b-form-input>
            <small class="text-danger">{{ error.registration_number }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-toddler"
            label="Nama Balita:"
            label-for="input-toddler"
          >
            <b-form-input
              id="input-toddler"
              v-model="form.toddler_name"
              placeholder="Nama Balita"
            ></b-form-input>
            <small class="text-danger">{{ error.toddler_name }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-birt-date"
            label="Tanggal Lahir:"
            label-for="input-birt-date"
          >
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-birt-date"
                  v-model="form.birt_date"
                  label="Tanggal Lahir:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.birt_date"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.birt_date }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-mother-registration-number"
            label="NIK Ibu:"
            label-for="input-mother-registration-number"
          >
            <b-form-input
              id="input-mother-registration-number"
              v-model="form.mother_registration_number"
              placeholder="NIK Ibu"
            ></b-form-input>
            <small class="text-danger">{{
              error.mother_registration_number
            }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-mother"
            label="Nama Ibu:"
            label-for="input-mother"
          >
            <b-form-input
              id="input-mother"
              v-model="form.mother_name"
              placeholder="Nama Ibu"
            ></b-form-input>
            <small class="text-danger">{{ error.mother_name }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-father-registration-number"
            label="NIK Ayah:"
            label-for="input-father-registration-number"
          >
            <b-form-input
              id="input-father-registration-number"
              v-model="form.father_registration_number"
              placeholder="NIK Ayah"
            ></b-form-input>
            <small class="text-danger">{{
              error.father_registration_number
            }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-father"
            label="Nama Ayah:"
            label-for="input-father"
          >
            <b-form-input
              id="input-father"
              v-model="form.father_name"
              placeholder="Nama Ayah"
            ></b-form-input>
            <small class="text-danger">{{ error.father_name }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-order"
            label="Anak ke - :"
            label-for="input-order"
          >
            <b-form-input
              id="input-order"
              v-model="form.order"
              placeholder="Anak Ke - "
            ></b-form-input>
            <small class="text-danger">{{ error.order }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-weight"
            label="Berat Badan Lahir (Kg):"
            label-for="input-weight"
          >
            <b-form-input
              id="input-weight"
              v-model="form.weight"
              placeholder="Berat Badan Lahir"
            ></b-form-input>
            <small class="text-danger">{{ error.weight }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-height"
            label="Panjang Badan Lahir (Cm):"
            label-for="input-height"
          >
            <b-form-input
              id="input-height"
              v-model="form.height"
              placeholder="Panjang Badan Lahir "
            ></b-form-input>
            <small class="text-danger">{{ error.height }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-head-circumference"
            label="Lingkar Kepala (Cm):"
            label-for="input-head-circumference"
          >
            <b-form-input
              id="input-head-circumference"
              v-model="form.head_circumference"
              placeholder="Lingkar Kepala "
            ></b-form-input>
            <small class="text-danger">{{ error.head_circumference }}</small>
          </b-form-group>

          <b-form-group label="Jenis Kelamin" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="radio-group-gender"
              v-model="form.gender"
              :aria-describedby="ariaDescribedby"
              name="radio-sub-component"
            >
              <b-form-radio value="male">Laki-laki</b-form-radio>
              <b-form-radio value="female">Perempuan</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group id="input-group-blood-type">
            <label for="input-description"
              >Golongan Darah: <em class="text-muted">opsional</em></label
            >
            <treeselect
              v-model="form.blood_type_id"
              :multiple="false"
              placeholder="Pilih Golongan Darah"
              :options="blood_types"
            />
            <small class="text-danger">{{ error.blood_type_id }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-p-2"
            label="Mendapat Buku KMS:"
            label-for="input-p-2"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="input-group-p-2"
              v-model="form.p2"
              :aria-describedby="ariaDescribedby"
              name="input-group-p-2"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ error.p2 }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-exclusive-breastfeeding"
            label="Mendapat ASI Ekslusif:"
            label-for="input-exclusive-breastfeeding"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="input-group-exclusive-breastfeeding"
              v-model="form.is_exclusive_breastfeeding"
              :aria-describedby="ariaDescribedby"
              name="input-group-exclusive-breastfeeding"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{
              error.is_exclusive_breastfeeding
            }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-p-15"
            label="Mendapat Inisiasi Menyusu Dini:"
            label-for="input-p-15"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="input-group-p-15"
              v-model="form.p15"
              :aria-describedby="ariaDescribedby"
              name="input-group-p-15"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ error.p15 }}</small>
          </b-form-group>

          <b-form-group id="input-group-description">
            <label for="input-description"
              >Keterangan: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              placeholder="Keterangan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/toddlers')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
  },
  data() {
    return {
      error: {
        toddler_name: "",
        father_name: "",
        mother_name: "",
        mother_registration_number: "",
        father_registration_number: "",
        registration_number: "",
        blood_type_id: "",
        weight: "",
        height: "",
        order: "",
        posyandu_id: "",
        gender: "",
        p2: "",
        p15: "",
        head_circumference: "",
        description: "",
        is_exclusive_breastfeeding: "",
      },
      // other
      blood_types: [],
      posyandu_list: [],
      menu2: false,
    };
  },
  methods: {
    async getBloodTypeOption() {
      let response = await module.setTreeSelect("api/blood-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.blood_types = response.data;
        this.blood_types.unshift({
          label: "Pilih Golongan Darah",
          id: 0,
          isDisabled: true,
        });
      }
    },

    async getPosyanduOption() {
      let response = await module.setTreeSelect("api/posyandu");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.posyandu_list = response.data;
        this.posyandu_list.unshift({
          label: "Pilih Posyandu",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/toddlers");
      }
    },
  },
  mounted() {
    this.getBloodTypeOption();
    this.getPosyanduOption();
  },
};
</script>